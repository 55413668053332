import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Button,
  TextField,
  CardContent,
  Checkbox,
  Typography,
  ListItemText
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useNavigate } from 'react-router-dom';
import { useGet, useGetWithBody, usePost, usePut } from '../../API/request';
import React, { useEffect, useState } from 'react';
import { BallTriangle } from 'react-loader-spinner';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Alert from '@material-ui/core/Alert';
import MasterList from '../Master/MasterList';

const PhraseTranslationAdd = () => {
  const navigate = useNavigate();
  const getU = useGet();
  const postU = usePost();
  const [isLoaded, setIsLoaded] = useState(true);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [languages, setLanguages] = useState([]);

  const [values, setValues] = useState({
    languagename: '',
    phrase: ''
  });

  const [errors, setErrors] = useState({
    languagename: false,
    phrase: false
  });

  const [alert, setAlert] = useState({
    txt: '',
    isVisible: false,
    type: 'error'
  });

  useEffect(() => {
    setIsLoaded(true);
    getU(`languages`)
      .then((resp) => {
        if (resp.status === 'success') {
          setLanguages(resp.data.languages);
        }
      })
      .catch(() => {
        showAlert(
          'error',
          'Произошла ошибка при загрузке языков, попробуйте перезайти'
        );
      })
      .finally(() => {
        setIsLoaded(false);
      });
  }, []);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
    setErrors({
      ...errors,
      [event.target.name]: false
    });
  };
  
  const showAlert = (type, text) => {
    setAlert({
      txt: text,
      type,
      isVisible: true
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        type,
        isVisible: false
      });

      setSubmitDisabled(false);
    }, 1400);
  };

  const validate = () => {
    let validComplete = true;
    let formErrors = { ...errors };
    if (values.languagename === '') {
      validComplete = false;
      formErrors.languagename = false;
      showAlert('error', 'Поле Язык не должно быть пустым');
    }
    if (values.phrase === '') {
      validComplete = false;
      formErrors.phrase = false;
      showAlert('error', 'Поле Переводимая фраза не должно быть пустым');
    }
    setErrors(formErrors);
    return validComplete;
  };

  const clearForm = () => {
    setValues({
      languagename: '',
      phrase: ''
    });
  };

  const submit = async () => {
    if (validate()) {
      setSubmitDisabled(true);

      const data = {
        languageKey: values.languagename,
        phrases: [values.phrase]
      };

      postU('translations', data)
        .then((resp) => {
          console.log(resp);
          if (resp.status === 'success') {
            showAlert('success', 'Phrase added');
            clearForm();
          } else {
            showAlert('error', 'Ошибка');
          }
        })
        .catch((err) => {
          console.log(err);
          showAlert('error', 'Ошибка сервера');
          setSubmitDisabled(false);
        })
        .finally(() => {});
    }
  };
  
  return (
    <>
      <Helmet>
        <title>Create new phrase</title>
      </Helmet>
      <Box sx={{ pt: 2 }}>
        <Container maxWidth={false}>
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Back
          </Button>
        </Container>
      </Box>
      <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
        <Container maxWidth={false}>
          <Box sx={{ pt: 2 }}>
            <form>
              <Card>
                <CardHeader title="Добавление фразы" />
                <Divider />
                <CardContent sx={{ position: 'relative' }}>
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <FormControl sx={{ mt: 2, mb: 1, width: '12%' }}>
                      <InputLabel id="languagename">Язык</InputLabel>
                      <Select
                        labelId="languagename"
                        name="languagename"
                        value={values.languagename}
                        label="Язык"
                        // renderValue={(selected) => selected.join(', ')}
                        onChange={handleChange}
                        error={errors.languagename}
                      >
                        {languages?.map((language) => (
                          <MenuItem value={language.name} key={language.id}>
                            {language.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField
                      sx={{ width: '86%' }}
                      label="Переводимая фраза"
                      margin="normal"
                      name="phrase"
                      onChange={handleChange}
                      type="text"
                      multiline
                      value={values.phrase}
                      variant="outlined"
                      error={errors.phrase}
                    />
                  </Box>

                  <Alert
                    severity={alert.type}
                    style={{ display: alert.isVisible ? 'flex' : 'none' }}
                  >
                    {alert.txt}
                  </Alert>
                </CardContent>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={submit}
                    disabled={submitDisabled}
                  >
                    Добавить
                  </Button>
                </Box>
              </Card>
            </form>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default PhraseTranslationAdd;

import { Navigate } from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout';
import MainLayout from './layouts/MainLayout';
import Account from './pages/Account/Account';
import UserAdd from './pages/User/UserAdd';
import UserInfo from './pages/User/UserInfo';
import UserEdit from './pages/User/UserEdit';
import UserList from './pages/User/UserList';
import MasterAdd from './pages/Master/MasterAdd';
import MasterInfo from './pages/Master/MasterInfo';
import MasterEdit from './pages/Master/MasterEdit';
import MasterList from './pages/Master/MasterList';
import ManagerAdd from './pages/Manager/ManagerAdd';
import ManagerEdit from './pages/Manager/ManagerEdit';
import ManagerInfo from './pages/Manager/ManagerInfo';
import ManagerList from './pages/Manager/ManagerList';
import Login from './pages/Login/Login';
import NotFound from './pages/NotFound/NotFound';
import Logs from './pages/Systems/Logs/Logs';
import Dashboard from './pages/Dashboard/Dashboard';
import EmailTemplateList from './pages/Templates/Email-Templates/EmailTemplateList';
import EmailTemplateCreate from './pages/Templates/Email-Templates/EmailTemplateCreate';
import EmailTemplateEdit from './pages/Templates/Email-Templates/EmailTemplateEdit';
import CommonSettings from './pages/Systems/CommonSettings/CommonSettings';
import UpdateSystem from './pages/Systems/UpdateSystem/UpdateSystem';
import SettingsExampl from './pages/Settings/SettingsExampl';
import EmailHistoryList from './pages/Templates/Email-Hstory/EmailHistoryList';
import FeedBackList from './pages/FeedBacks/FeedBackList';
import FeedBackEdit from './pages/FeedBacks/FeedBackEdit';
import ConfigurationKeysList from './pages/ConfigurationKeys/ConfigurationKeysList';
import ConfigurationKeysAdd from './pages/ConfigurationKeys/ConfigurationKeysAdd';
import ConfigurationKeysEdit from './pages/ConfigurationKeys/ConfigurationKeysEdit';
import ServerLogs from './pages/AppWork/ServerLogs';
import AppLogs from './pages/AppWork/AppLogs';
import MobileLogs from './pages/AppWork/MobileLogs';
import AppStatistics from './pages/AppStatistics/AppStatistics';
import AppStatisticsEventsList from './pages/AppStatistics/AppStatisticsEventsList';
import AppStatisticsEventsAdd from './pages/AppStatistics/AppStatisticsEventsAdd';
import AppStatisticsEventsEdit from './pages/AppStatistics/AppStatisticsEventsEdit';
import LanguageList from './pages/Language/LanguageList';
import LanguageAdd from './pages/Language/LanguageAdd';
import LanguageEdit from './pages/Language/LanguageEdit';
import PhraseTranslationList from './pages/PhraseTranslations/PhraseTranslationList';
import PhraseTranslationEdit from './pages/PhraseTranslations/PhraseTranslationEdit';
import PhraseTranslationAdd from './pages/PhraseTranslations/PhraseTranslationAdd';
import LogView from './pages/AppWork/LogView';
import SmallFieldEdit from './pages/PlayFields/SmallField/SmallFieldEdit';
import SmallFieldAdd from './pages/PlayFields/SmallField/SmallFieldAdd';
import SmallFieldList from './pages/PlayFields/SmallField/SmallFieldList';
import LogUser from './pages/AppWork/LogUser';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'account', element: <Account /> },
      { path: 'dashboard', element: <Dashboard /> },

      { path: 'user/:id', element: <UserInfo /> },
      { path: 'user/edit/:id', element: <UserEdit /> },
      { path: 'user/add', element: <UserAdd /> },
      { path: 'users', element: <UserList /> },

      { path: 'master/:id', element: <MasterInfo /> },
      { path: 'master/edit/:id', element: <MasterEdit /> },
      { path: 'master/add', element: <MasterAdd /> },
      { path: 'masters', element: <MasterList /> },

      { path: 'manager/:id', element: <ManagerInfo /> },
      { path: 'manager/edit/:id', element: <ManagerEdit /> },
      { path: 'manager/add', element: <ManagerAdd /> },
      { path: 'managers', element: <ManagerList /> },

      { path: 'language/edit/:id', element: <LanguageEdit /> },
      { path: 'language/add', element: <LanguageAdd /> },
      { path: 'languages', element: <LanguageList /> },

      {
        path: 'phrase-translations/edit/:id',
        element: <PhraseTranslationEdit />
      },
      { path: 'phrase-translations/add', element: <PhraseTranslationAdd /> },
      { path: 'phrase-translations', element: <PhraseTranslationList /> },

      { path: 'feedbacks/edit/:id', element: <FeedBackEdit /> },
      { path: 'feedbacks', element: <FeedBackList /> },

      { path: 'email-templates', element: <EmailTemplateList /> },
      { path: 'email-history', element: <EmailHistoryList /> },
      { path: 'email-templates/create', element: <EmailTemplateCreate /> },
      { path: 'email-templates/edit/:id', element: <EmailTemplateEdit /> },

      { path: 'server-logs', element: <ServerLogs /> },
      { path: 'app-logs', element: <AppLogs /> },
      { path: 'mobile-logs', element: <MobileLogs /> },
      { path: 'view-log', element: <LogView /> },
      { path: 'user-log', element: <LogUser /> },

      { path: 'small-field/edit/:id', element: <SmallFieldEdit /> },
      { path: 'small-field/add', element: <SmallFieldAdd /> },
      { path: 'small-field', element: <SmallFieldList /> },

      { path: 'app-statistics', element: <AppStatistics /> },
      { path: 'app-statistics/events', element: <AppStatisticsEventsList /> },
      {
        path: 'app-statistics/events/add',
        element: <AppStatisticsEventsAdd />
      },
      {
        path: 'app-statistics/events/edit/:id',
        element: <AppStatisticsEventsEdit />
      },

      { path: 'logs', element: <Logs /> },
      { path: 'common-settings', element: <CommonSettings /> },
      { path: 'update-system', element: <UpdateSystem /> },

      { path: 'settings', element: <SettingsExampl /> },

      { path: 'configuration/keys', element: <ConfigurationKeysList /> },
      { path: 'configuration/keys/add', element: <ConfigurationKeysAdd /> },
      {
        path: 'configuration/keys/edit/:id',
        element: <ConfigurationKeysEdit />
      },

      { path: '', element: <Navigate to="/app/users" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/app/users" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;

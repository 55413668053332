import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import {
  Box,
  Container,
  Avatar,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TableFooter,
  TablePagination,
  TextField,
  Divider,
  Checkbox
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import { useDelete, useGet } from '../../API/request';
import { useConfirm } from '../../components/Confirm/index';
import { BallTriangle } from 'react-loader-spinner';
import '../../styles/All.css';


const PhraseTranslationList = () => {

  // Инициализация необходимых хуков, состояний и функций
  const navigate = useNavigate();
  const confirm = useConfirm();
  const getU = useGet();
  const deleteU = useDelete();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoaded, setIsLoaded] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [phrases, setPhrases] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);

  const [queryParams, setQueryParams] = useState({
    phrase: searchParams.get('phrase') || ''
  });

  //Функция для загрузки списка переводов фраз
  const loadPhrases = async () => {
    setIsDataLoading(true);
    setIsLoaded(true);

    let endpoint = `translations/all?page=${page + 1}&limit=${limit}`;


    if (queryParams.phrase !== '') {
      endpoint += `&phrase=${searchParams.get('phrase')}`;
    }

    const resp = await getU(endpoint);

      // Дальнейшая обработка значения resp
      if (resp.status === 'success') {
        console.log(resp)
        const phraseTranslations = resp.data.phraseTranslations;

        const newData = phraseTranslations
        const modifData = [];
      if (Array.isArray(newData)) {
      newData.forEach((el) => {

      const translations = el.phrase.translations || [];

      const tranlatedArr = translations.map((translation) => {
        return {
          id: el.phrase.id,
          language: translation.languageKey,
          languageId: el.languageId,
          translate: translation.text
          };
      });

      const findElement = modifData.find((x) => x.phrase === el.phrase);

      if (el.phrase !== findElement?.phrase) {
      const pushElement = {
        id: el.phrase.id,
        phrase: el.phrase,
        tranlatedArr: tranlatedArr
        };
      modifData.push(pushElement);
      } else {
      const index = modifData.indexOf(findElement);
       modifData[index].tranlatedArr.push(...tranlatedArr);
      }
      })
    }
      setPhrases(modifData);
      setCount(resp.data.currentCount || 0);
      }
      setIsDataLoading(false);
      setIsLoaded(false);
  }

  // Обработчик изменения страницы
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
};

  // Обработчик изменения лимита
  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
    setPage(0);
};

  // Эффект для удаления параметра 'phrase' из searchParams
  useEffect(() => {
    if (queryParams.phrase === '') {
      searchParams.delete('phrase');
      setSearchParams(searchParams);
    }
  }, [queryParams]);

  // Обработчик изменения параметров запроса
  const handleChangeQueryParams = (event) => {
    setQueryParams({
      ...queryParams,
      [event.target.name]: event.target.value
    });
  };

  // Обработчик фильтрации по параметрам запроса
  const handleFilterQueryParams = () => {
    const params = {};
    if (queryParams.phrase !== '') {
      params.phrase = queryParams.phrase;
    }
    console.log(Object.keys(params).length !== 0);
    if (Object.keys(params).length !== 0) {
      setSearchParams(params);
    }
  };

   // Эффект для загрузки списка переводов фраз при изменении страницы, лимита или параметров запроса
  useEffect(() => {
    loadPhrases();
  }, [page, limit, searchParams]);


  // Выводим загрузчик, если данные еще не загружены
  if (isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

 // Отображение компонента
  return (
    <>
      <Helmet>
        <title>Переводы фраз</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">Переводы фраз</Box>
      </Box>
      <Box
        sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}
      >
        <Container maxWidth={false}>
          {isDataLoading ? (
            <UserListSkelet />
          ) : (
            <>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{ marginLeft: 2 }}>
                  <Link to="/app/phrase-translations/add">
                    <Button color="primary" variant="contained" style={{ display: 'none' }} >
                      Добавить фразу
                    </Button>
                  </Link>
                </Box>
              </Box>
              <Box sx={{ pt: 3 }}>
                <Card>
                  <PerfectScrollbar>
                    <Box sx={{ maxWidth: 1300 }}>
                      <Box
                        sx={{
                          display: 'flex',

                          mx: 2,
                          mb: 1
                        }}
                      >
                        <TextField
                          fullWidth
                          label="Поиск по фразам"
                          margin="normal"
                          name="phrase"
                          onChange={handleChangeQueryParams}
                          type="text"
                          value={queryParams.phrase}
                          variant="outlined"
                          style={{ width: '40%', marginRight: 20 }}
                        />
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={handleFilterQueryParams}
                          sx={{ mt: 2, mb: 1 }}
                        >
                          Применить
                        </Button>
                      </Box>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ width: 80 }}>Id</TableCell>
                            <TableCell>Преводимая фраза</TableCell>
                            <TableCell>Значение EN</TableCell>
                            <TableCell>Значение UA</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                        {phrases?.map((phrase) => (
                          <TableRow hover key={phrase.id}>
                              <TableCell sx={{ width: 80 }}>
                                {phrase.id}
                              </TableCell>
                              <TableCell>{phrase.phrase.text}</TableCell>
                              <TableCell>
                              {phrase.tranlatedArr
                              .find((el) =>
                               el.language === 'EN'
                                )?.translate || '---'}
                              </TableCell>
                              <TableCell>
                              {phrase.tranlatedArr
                              .find((el) =>
                               el.language === 'UA'
                                )?.translate || '---'}
                              </TableCell>
                              <TableCell>
                                <Box sx={{ display: 'flex' }}>
                                  <Box sx={{ display: 'flex' }}>
                                    <Box sx={{ ml: 2 }}>
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        disabled={phrase?.role === 'admin'}
                                        onClick={() =>
                                          navigate(
                                            `/app/phrase-translations/edit/${phrase.id}`,
                                            {
                                              state: {
                                                phrase: phrase.phrase,
                                                valueEN:
                                                  phrase.tranlatedArr.find(
                                                    (el) => el.language === 'EN'
                                                  ),
                                                valueUA:
                                                  phrase.tranlatedArr.find(
                                                    (el) => el.language === 'UA'
                                                  )
                                              }
                                            }
                                          )
                                        }
                                      >
                                        Редакт.
                                      </Button>
                                    </Box>
                                  </Box>
                                </Box>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        <TableFooter sx={{ maxWidth: 1300, marginLeft: '50px' }}>
                          <TableRow>
                            <TablePagination
                              labelRowsPerPage={
                                <span>Кол-во строк на странице:</span>
                              }
                              rowsPerPageOptions={[10, 20, 30, 40, 50]}
                              colSpan={7}
                              count={count}
                              rowsPerPage={limit}
                              page={page}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeLimit}
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </Box>
                  </PerfectScrollbar>
                </Card>
              </Box>
            </>
          )}
        </Container>
      </Box>
    </>
  );
};

export default PhraseTranslationList;

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Button,
  Card,
  CardHeader,
  Divider,
  CardContent,
  CircularProgress,
  TextField,
  Alert,
  Grid,
  Typography,
  Checkbox
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import UserFormEdit from '../../components/Users/UserFormEdit';
import { useGet, usePost, usePut } from '../../API/request';
import { BallTriangle } from 'react-loader-spinner';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import '../../styles/Avatar/style.css';



const PhraseTranslationEdit = () => {
  const { id } = useParams(); // Получение параметра id из URL
  const navigate = useNavigate(); // Получение функции навигации
  const getU = useGet(); // Функция для выполнения GET-запросов
  const postU = usePost(); // Функция для выполнения POST-запросов
  const putU = usePut(); // Функция для выполнения PUT-запросов
  const { state } = useLocation(); // Получение состояния из объекта location

  const [submitDisabled, setSubmitDisabled] = useState(false); // Состояние для блокировки кнопки отправки
  const [nothingChanged, setNothingChanged] = useState(true); // Состояние для проверки, были ли изменения в форме

  // Состояние для хранения значений формы
  const [values, setValues] = useState({
    phrase: state.phrase,
    translateEN: state.valueEN?.translate || '',
    translateUA: state.valueUA?.translate || '',
  });
  
  // Состояние для отслеживания ошибок валидации
  const [errors, setErrors] = useState({
    phrase: false,
    translateEN: false,
    translateUA: false
  }); 

  // Состояние для отображения алертов
  const [alert, setAlert] = useState({
    txt: '',
    isVisible: false,
    type: 'error'
  }); 

  // Обработчик изменения значений формы
  const handleChange = (event) => {
    setNothingChanged(false);
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
    setErrors({
      ...errors,
      [event.target.name]: false
    });
  }; 

  // Функция для отображения алерта
  const showAlert = (type, text) => {
    setAlert({
      txt: text,
      type,
      isVisible: true
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        type,
        isVisible: false
      });

      setSubmitDisabled(false);
    }, 2500);
  };

  // Функция валидации формы
  const validate = () => {
    let validComplete = true;
    let formErrors = { ...errors };

    if (values.phrase === '') {
      validComplete = false;
      formErrors.phrase = false;
      showAlert('error', 'Поле Переводимая фраза не должно быть пустым');
    }
    if (values.translateEN === '') {
      validComplete = false;
      formErrors.translateEN = false;
      showAlert('error', 'Поле Значение EN не должно быть пустым');
    }
    if (values.translateUA === '') {
      validComplete = false;
      formErrors.translateUA = false;
      showAlert('error', 'Поле Значение UA не должно быть пустым');
    }

    setErrors(formErrors);
    return validComplete;
  };

  // Функция отправки формы
  const submit = async () => {
    
    if (nothingChanged) {
      showAlert('error', 'Нет изменений');
      return;
    }
  
    if (validate()) {
      setSubmitDisabled(true);
      console.log('123')
      try {
        if (values.translateEN) {
          const dataEN = {
            text: values.translateEN,
            languageKey: 'EN'
          };
          const pathIdEN = id;
          const responseEN = await putU(`translations/${pathIdEN}`, dataEN);
          console.log(dataEN)
          

          if (responseEN.status === 'success') {
            showAlert('success', 'Данные на английском языке успешно обновлены');
          } else {
            showAlert('error', 'Ошибка при обновлении данных на английском языке');
          }
        }
  
        if (values.translateUA) {
          const dataUA = {
            text: values.translateUA,
            languageKey: 'UA'
          };
          const pathIdUA = id;
          const responseUA = await putU(`translations/${pathIdUA}`, dataUA);
          console.log(dataUA)
          if (responseUA.status === 'success') {
            showAlert('success', 'Данные на украинском языке успешно обновлены');
          } else {
            showAlert('error', 'Ошибка при обновлении данных на украинском языке');
          }
        }
      } catch (error) {
        showAlert('error', 'Ошибка сервера');
      } finally {
        setSubmitDisabled(false);
      }
    }
  };
 
  
  return (
    <>
      <Helmet>
        <title>Edit phrase</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Назад
          </Button>
          Фраза {values.id}
        </Box>
        <ul className="headerList">
          <li onClick={() => navigate(-1)}>Переводы фраз</li>
          <li>/</li>
          <li>Редактирование</li>
        </ul>
      </Box>

      {/*info*/}
      <Box sx={{ backgroundColor: 'background.default', pt: 3 }}>
        <Container maxWidth={false}>
          <Box
            sx={{
              paddingBottom: 1
            }}
          >
            <form>
              <Card>
                <CardHeader title="Редактирование фразы" />
                <Divider />
                <CardContent sx={{ position: 'relative' }}>
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <TextField
                      sx={{ width: '33%' }}
                      label="Переводимая фраза"
                      margin="normal"
                      name="phrase"
                      // onChange={handleChange}
                      type="text"
                      multiline
                      value={values.phrase.text}
                      variant="outlined"
                      error={errors.phrase}
                    />
                    <TextField
                      sx={{ width: '33%' }}
                      label="Значение EN"
                      margin="normal"
                      name="translateEN"
                      onChange={handleChange}
                      type="text"
                      multiline
                      value={values.translateEN}
                      variant="outlined"
                      error={errors.translateEN}
                    />
                    <TextField
                      sx={{ width: '33%' }}
                      label="Значение UA"
                      margin="normal"
                      name="translateUA"
                      onChange={handleChange}
                      type="text"
                      multiline
                      value={values.translateUA}
                      variant="outlined"
                      error={errors.translateUA}
                    />
                  </Box>

                  <Alert
                    severity={alert.type}
                    style={{ display: alert.isVisible ? 'flex' : 'none' }}
                  >
                    {alert.txt}
                  </Alert>
                </CardContent>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={submit}
                    disabled={submitDisabled}
                  >
                    Добавить
                  </Button>
                </Box>
              </Card>
            </form>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default PhraseTranslationEdit;

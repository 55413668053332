import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Button,
  Card,
  CardHeader,
  Divider,
  CardContent,
  CircularProgress,
  TextField,
  Alert,
  Grid,
  Typography,
  Checkbox
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useParams, useNavigate } from 'react-router-dom';
import UserFormEdit from '../../components/Users/UserFormEdit';
import { useGet, usePut } from '../../API/request';
import { BallTriangle } from 'react-loader-spinner';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import '../../styles/Avatar/style.css';

const LanguageEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const getU = useGet();
  const putU = usePut();

  const [isLoaded, setIsLoaded] = useState(true);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [nothingChanged, setNothingChanged] = useState(true);

  const [values, setValues] = useState({
    name: '',
    isMain: false
  });
  const [errors, setErrors] = useState({
    name: false
  });

  const [alert, setAlert] = useState({
    txt: '',
    isVisible: false,
    type: 'error'
  });

  const handleChange = (event) => {
    setNothingChanged(false);
    if (event.target.checked) {
      setValues({
        ...values,
        isMain: !values.isMain
      });
    } else {
      setValues({
        ...values,
        [event.target.name]: event.target.value
      });

      setErrors({
        ...errors,
        [event.target.name]: false
      });
    }
  };

  const showAlert = (type, text) => {
    setAlert({
      txt: text,
      type,
      isVisible: true
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        type,
        isVisible: false
      });

      setSubmitDisabled(false);
    }, 2500);
  };

  const validate = () => {
    let validComplete = true;
    let formErrors = { ...errors };

    if (values.name === '') {
      validComplete = false;
      formErrors.name = false;
      showAlert('error', 'Поле Language name не должно быть пустым');
    }

    setErrors(formErrors);
    return validComplete;
  };

  const submit = async () => {
    if (nothingChanged) {
      showAlert('error', 'Нет изменений');
      return;
    }

    if (validate()) {
      setSubmitDisabled(true);

      const data = {
        name: values.name.toUpperCase(),
        isMain: values.isMain
      };

      putU(`languages/${id}`, data)
        .then((resp) => {
          if (resp.status === 'success') {
            showAlert('success', 'Данные успешно обновленны');
          } else {
            showAlert('error', 'Ошибка');
          }
        })
        .catch((err) => {
          showAlert('error', 'Ошибка сервера');
        })
        .finally(() => {
          setSubmitDisabled(false);
        });
    }
  };

  useEffect(() => {
    setIsLoaded(true);
    getU(`languages/${id}`)
      .then((resp) => {
        if (resp.status === 'success') {
          const data = {
            name: resp.data.language.name,
            isMain: resp.data.language.isMain
          };
          setValues(data);
          setIsLoaded(false);
        }
      })
      .catch(() => {
        showAlert(
          'error',
          'Произошла ошибка при загрузке ролей, попробуйте перезайти'
        );
      });
  }, []);

  if (isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Edit language</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Назад
          </Button>
          Язык {values.name}
        </Box>
        <ul className="headerList">
          <li onClick={() => navigate(-1)}>Языки</li>
          <li>/</li>
          <li>Редактирование</li>
        </ul>
      </Box>

      {/*info*/}
      <Box sx={{ backgroundColor: 'background.default', pt: 3 }}>
        <Container maxWidth={false}>
          <Box
            sx={{
              paddingBottom: 1
            }}
          >
            <form>
              <Card>
                <CardHeader title="Редактирование языка" />
                <Divider />
                <CardContent sx={{ position: 'relative' }}>
                  <Box display={'flex'} alignItems={'center'}>
                    <TextField
                      sx={{ mr: 3 }}
                      label="Language name"
                      margin="normal"
                      name="name"
                      onChange={handleChange}
                      type="text"
                      value={values.name}
                      variant="outlined"
                      error={errors.languagename}
                    />
                    <Typography variant="h5" mr={1}>
                      Главный
                    </Typography>
                    <Checkbox
                      checked={values.isMain}
                      size="large"
                      onChange={handleChange}
                    />
                  </Box>

                  <Alert
                    severity={alert.type}
                    style={{ display: alert.isVisible ? 'flex' : 'none' }}
                  >
                    {alert.txt}
                  </Alert>
                </CardContent>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={submit}
                    disabled={submitDisabled}
                  >
                    Добавить
                  </Button>
                </Box>
              </Card>
            </form>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default LanguageEdit;

import {Helmet} from 'react-helmet';
import {
  Box,
  Container,
  Button,
  TextField,
  CardContent,
  Checkbox,
  Typography
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {useNavigate} from 'react-router-dom';
import {
  useGet,
  usePost,
  usePostWithoutApi,
  usePut
} from '../../../API/request';
import React, {useEffect, useState} from 'react';
import {BallTriangle} from 'react-loader-spinner';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Alert from '@material-ui/core/Alert';
import MasterList from '../../Master/MasterList';

const LanguageAdd = () => {
  const navigate = useNavigate();
  const postU = usePostWithoutApi();

  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [uploadedImg, setUploadedImg] = useState('/static/images/defphoto.jpg');

  const [values, setValues] = useState({
    name: '',
    image: '',
    cell: 0
  });
  const [errors, setErrors] = useState({
    name: false,
    image: false,
    cell: 0
  });

  const [alert, setAlert] = useState({
    txt: '',
    isVisible: false,
    type: 'error'
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
    setErrors({
      ...errors,
      [event.target.name]: false
    });
  };

  const showAlert = (type, text) => {
    setAlert({
      txt: text,
      type,
      isVisible: true
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        type,
        isVisible: false
      });

      setSubmitDisabled(false);
    }, 1400);
  };

  const validate = () => {
    let validComplete = true;
    let formErrors = {...errors};

    if (values.name === '') {
      validComplete = false;
      formErrors.name = false;
      showAlert('error', 'Поле Название предприятия не должно быть пустым');
    }

    if (values.cell === '' || values.cell<=0) {
      validComplete = false;
      formErrors.cell = false;
      showAlert('error', 'Поле "Позиция предприятия" должно быть больше нуля');
    }
    setErrors(formErrors);
    return validComplete;
  };

  const clearForm = () => {
    setValues({
      name: '',
      image: '',
      cell: 0,
    });
  };

  const avaUploaded = (event) => {
    setUploadedImg(URL.createObjectURL(event.target.files[0]));
    setValues({
      ...values,
      image: event.target.files[0]
    });
  };

  const submit = async () => {
    if (validate()) {
      setSubmitDisabled(true);

      const imageData = new FormData();
      imageData.append('image', values.image);
      imageData.append ('name', values.name);
      imageData.append ('cellNumber', String(values.cell));

     /* const data = {
        name: values.name,
        image:
          'https://www.digiseller.ru/preview/980778/p1_3477369_4bc067db.jpeg',
        cellNumber: String(values.cell)
      };*/

      postU('api/tiles', imageData)
        .then((resp) => {
          if (resp.status === 'success') {
            showAlert('success', 'Tiles success added');
            clearForm();
          } else {
            showAlert('error', 'Ошибка');
          }
        })
        .catch((err) => {
          showAlert('error', 'Ошибка сервера');
          setSubmitDisabled(false);
        })
        .finally(() => {
        });
    }
  };

  return (
    <>
      <Helmet>
        <title>Добавление предприятия</title>
      </Helmet>
      <Box sx={{pt: 2}}>
        <Container maxWidth={false}>
          <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
            Back
          </Button>
        </Container>
      </Box>
      <Box sx={{backgroundColor: 'background.default', minHeight: '100%'}}>
        <Container maxWidth={false}>
          <Box sx={{pt: 2}}>
            <form>
              <Card>
                <CardHeader title="Добавление предприятия"/>
                <Divider/>
                <CardContent sx={{position: 'relative'}}>
                  <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
                    <TextField
                      sx={{mr: 3, width: '70%'}}
                      label="Название предприятия"
                      fullWidth
                      margin="normal"
                      name="name"
                      onChange={handleChange}
                      type="text"
                      value={values.name}
                      variant="outlined"
                      error={errors.name}
                    />
                    <TextField
                      sx={{mr: 3, width: '70%'}}
                      label="Позиция предприятия"
                      fullWidth
                      margin="normal"
                      name="cell"
                      onChange={handleChange}
                      type="text"
                      value={values.cell}
                      variant="outlined"
                      error={errors.cell}
                    />
                    <CardContent sx={{position: 'relative'}}>
                      <div className="itemWrapper">
                        <div className="container">
                          <input
                            accept="xlsx/*"
                            type="file"
                            style={{display: 'none'}}
                            id={1}
                            onChange={avaUploaded}
                          />
                          <label htmlFor={1}>
                            <img src={uploadedImg} className="itemImg"/>
                            <div className="middle"/>
                          </label>
                        </div>
                        <div className="help-text">
                          Доступны следующие расширения: .png .jpg .svg .bmp
                          .tga .webp
                        </div>
                      </div>
                    </CardContent>
                  </Box>

                  <Alert
                    severity={alert.type}
                    style={{display: alert.isVisible ? 'flex' : 'none'}}
                  >
                    {alert.txt}
                  </Alert>
                </CardContent>
                <Divider/>
                <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={submit}
                    disabled={submitDisabled}
                  >
                    Добавить
                  </Button>
                </Box>
              </Card>
            </form>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default LanguageAdd;

import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Avatar,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TableFooter,
  TablePagination,
  TextField,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Paper,
  Grid,
  Tooltip
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import { useDelete, useGet } from '../../API/request';
import { useConfirm } from '../../components/Confirm';
import { BallTriangle } from 'react-loader-spinner';
import moment from 'moment';
import { Switch, FormControlLabel } from '@mui/material'

const AppLogs = () => {
  const navigate = useNavigate();
  const getU = useGet();
  const deleteU = useDelete();
  const confirm = useConfirm();

  const [isLoaded, setIsLoaded] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(true);

  const [logs, setLogs] = useState([]);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [appType, setAppType] = useState('');
  const [status, setStatus] = useState('');
  const [type, setType] = useState('');
  const [path, setPath] = useState('');
  const [device, setDevice] = useState('');
  const [message, setMessage] = useState('');
  const [filterMethod, setFilterMethod] = useState('');
  const [filterValue, setFilterValue] = useState('');

  const loadData = () => {
    setIsDataLoading(true);
    setIsLoaded(true);

    let endpoint = `logs/users/all?appType=web&page=${page + 1}&limit=${limit}`;

    if (startDate) {
      const isoStartDate = new Date(startDate).toISOString();
      endpoint += `&startDate=${isoStartDate}`;
      } else {
        const minDate = new Date('1970-01-01T00:00:00Z');
        const isoMinDate = minDate.toISOString();
        endpoint += `&startDate=${isoMinDate}`;
      }

    if (endDate) {
      const endDateObj = new Date(endDate);
      endDateObj.setMinutes(endDateObj.getMinutes() + 1);
      const isoEndDate = endDateObj.toISOString();
      endpoint += `&endDate=${isoEndDate}`;
    }

    if (filterMethod && filterValue) {
      endpoint += `&${filterMethod}=${filterValue}`;
    }

    getU(endpoint)
      .then((resp) => {
        console.log(resp);
        if (resp.status === 'success') {
          setLogs(resp.data.logs);
          setCount(resp.data.currentCount || 0);
        }
      })
      .catch((err) => {
        console.log(err.response);
        setLogs([]);
        setCount(0);
      })
      .finally(() => {
        setIsLoaded(false);
        setIsDataLoading(false);
      });
  };

  const handleFilterMethodChange = (event) => {
    setFilterMethod(event.target.value);
  };

  const handleFilterValueChange = (event) => {
    setFilterValue(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleResetFilters = () => {
    setFilterMethod('');
    setFilterValue('');
    setStartDate('');
    setEndDate('');
  };

  const cleareLogs = () => {
    confirm({
      title: 'Очистка логов',
      content: 'Вы уверены, что хотите очистить логи?',
      onConfirm: () => {
        deleteU(`logs/users?appType=web`)
          .then((resp) => {
            if (resp.status === 'success') {
              loadData();
            }
          })
          .catch((e) => {
            console.log(e.response);
          });
      }
    });
  };

  const formattedDateTime = (createdAt) => {
    const dateObj = new Date(createdAt);
    return dateObj.toLocaleString('ru-RU', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
  });
}


  useEffect(() => {
    loadData();
  }, [page, limit]);

  if (isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Логи приложения</title>
      </Helmet>
      <Box sx={{ pt: 2 }}>
        <Container maxWidth={false}>
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Назад
          </Button>
        </Container>
      </Box>

      <Box className="headerWrapper">
        <Box className="headerTitle">Логи приложения</Box>
      </Box>
      <Box
        sx={{ backgroundColor: 'background.default', minHeight: '90%', py: 3 }}
      >
        <Container maxWidth={false}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 2 }}>
            <Button color="error" variant="contained" onClick={cleareLogs}>
              Очистить историю
            </Button>
          </Box>
          <Box>
            <Paper sx={{ p: 4,}}>
              <TextField
                sx={{ marginRight: 2 }}
                label="Начальная дата"
                type="datetime-local"
                value={startDate}
                onChange={handleStartDateChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <TextField
                sx={{ marginRight: 2 }}
                label="Конечная дата"
                type="datetime-local"
                value={endDate}
                onChange={handleEndDateChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <FormControl variant="outlined" sx={{ minWidth: 240, marginRight: 2 }}>
                <InputLabel id="filter-method-label">Метод фильтра</InputLabel>
                <Select
                  labelId="filter-method-label"
                  id="filter-method"
                  value={filterMethod}
                  onChange={handleFilterMethodChange}
                  label="Метод фильтра"
                >
                  <MenuItem value="">"Очистить метод"</MenuItem>
                  <MenuItem value="status">Status</MenuItem>
                  <MenuItem value="type">Type</MenuItem>
                  <MenuItem value="device">Device</MenuItem>
                  <MenuItem value="message">Message</MenuItem>
                  <MenuItem value="path">Path</MenuItem>
                </Select>
              </FormControl>

              {filterMethod === "status" && (
              <FormControl variant="outlined" sx={{ minWidth: 300, marginRight: 2 }}>
                <InputLabel id="filter-value-label">Значение фильтра</InputLabel>
                <Select
                  labelId="filter-value-label"
                  id="filter-value"
                  value={filterValue}
                  onChange={handleFilterValueChange}
                  label="Значение фильтра"
                >
                  <MenuItem value="">Очистить значение</MenuItem>
                  <MenuItem value="success">Success</MenuItem>
                  <MenuItem value="error">Error</MenuItem>
                </Select>
              </FormControl>
              )}

              {filterMethod !== "status" ? (
                <TextField
                  sx={{ minWidth: 300, marginRight: 2 }}
                  label="Значение фильтра"
                  value={filterValue}
                  onChange={handleFilterValueChange}
                />
              ) : null}

              <Button
                sx={{
                   width: 101,
                   height : 55
                }}
                color="primary"
                variant="contained"
                onClick={loadData}>
                Фильтр
              </Button>

              <Grid item xs={12}>
                  <Box sx={{ position: 'left', bottom: 0, left: 0, marginTop: 2 }}>
                    <Button
                      color="error"
                      variant="contained"
                      onClick={handleResetFilters}
                    >
                      Сбросить фильтры
                    </Button>
                  </Box>
              </Grid>
            </Paper>
          </Box>
          <Box sx={{ pt: 3 }}>
            <Card>
              <PerfectScrollbar>
                <Box sx={{ minWidth: 1050 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Device</TableCell>
                        <TableCell>Message</TableCell>
                        <TableCell>Path</TableCell>
                        <TableCell>User ID</TableCell>
                        <TableCell>Created At</TableCell>

                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {logs.map((log) => (
                        <TableRow hover key={log.id}>
                          <TableCell>{log.id || '---'}</TableCell>
                          <TableCell>{log.status || '---'}</TableCell>
                          <TableCell>{log.type || '---'}</TableCell>
                          <TableCell>{log.device || '---'}</TableCell>
                          <TableCell>
                            <Tooltip title={log.message || '---'}>
                              <span style={{ display: 'inline-block',
                               maxWidth: '150px',
                               overflow: 'hidden',
                               textOverflow: 'ellipsis',
                               whiteSpace: 'nowrap' }}>
                              {log.message || '---'}
                              </span>
                            </Tooltip>
                            </TableCell>
                          <TableCell>{log.path || '---'}</TableCell>
                          <TableCell>{log.userId || '---'}</TableCell>
                          <TableCell>{formattedDateTime(log.createdAt) || '---'}</TableCell>
                          <TableCell>
                            <Box sx={{ display: 'flex' }}>
                              <Box style={{ marginLeft: '20px' }}>
                                <Button
                                  color="primary"
                                  variant="contained"
                                  onClick={() =>
                                    navigate('/app/view-log', {
                                      state: {
                                        id: log.id,
                                        message: log.message
                                      }
                                    })
                                  }
                                >
                                  DATA
                                </Button>
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          labelRowsPerPage={
                            <span>Кол-во строк на странице:</span>
                          }
                          rowsPerPageOptions={[10, 20, 30, 40, 50]}
                          colSpan={7}
                          count={count}
                          rowsPerPage={limit}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeLimit}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </Box>
              </PerfectScrollbar>
            </Card>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default AppLogs;

import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Container,
  Avatar,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TableFooter,
  TablePagination,
  TextField,
  Divider
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import { useDelete, useGet } from '../../API/request';
import { useConfirm } from '../../components/Confirm';
import { BallTriangle } from 'react-loader-spinner';
import moment from 'moment';

const LogView = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const getU = useGet();
  const deleteU = useDelete();
  const confirm = useConfirm();

  useEffect(() => {
    console.log(state);
  }, [state]);

  const [isLoaded, setIsLoaded] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(true);

  const [logs, setLogs] = useState([]);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);

  const loadData = () => {
    setIsDataLoading(true);
    setIsLoaded(true);

    let endpoint = `logs/app/all?page=${page + 1}&limit=${limit}`;

    getU(endpoint)
      .then((resp) => {
        console.log(resp);
        if (resp.status === 'success') {
          setLogs(resp.data.logs);
          setCount(resp.data.currentCount || 0);
        }
      })
      .catch((err) => {
        console.log(err.response);
        setLogs([]);
        setCount(0);
      })
      .finally(() => {
        setIsLoaded(false);
        setIsDataLoading(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const cleareLogs = () => {
    confirm({
      title: 'Очистка логов',
      content: 'Вы уверены, что хотите очистить логи?',
      onConfirm: () => {
        deleteU(`logs/app`)
          .then((resp) => {
            if (resp.status === 'success') {
              loadData();
            }
          })
          .catch((e) => {
            console.log(e.response);
          });
      }
    });
  };

  // useEffect(() => {
  //   loadData();
  // }, [page, limit]);

  return (
    <>
      <Helmet>
        <title>Log</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Назад
          </Button>
          Log {state.id}
        </Box>
        <ul className="headerList">
          <li onClick={() => navigate(-1)}>Logs</li>
          <li>/</li>
          <li>Log {state.id}</li>
        </ul>
      </Box>
      <Box
        sx={{ backgroundColor: 'background.default', minHeight: '90%', py: 3 }}
      >
        <Container maxWidth={false}>
          <Box sx={{ pt: 3 }}>
            <Card>
              <PerfectScrollbar>
                <Box sx={{ minWidth: 1050, p: 3 }}>{state.message}</Box>
              </PerfectScrollbar>
            </Card>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default LogView;

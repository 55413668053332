import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Avatar,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TableFooter,
  TablePagination,
  TextField,
  Divider,
  Checkbox
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../../skeletons/UserListSkelet';
import { useDelete, useGet } from '../../../API/request';
import { useConfirm } from '../../../components/Confirm/index';
import { BallTriangle } from 'react-loader-spinner';
import '../../../styles/All.css';

const SmallFieldList = () => {
  const confirm = useConfirm();
  const getU = useGet();
  const deleteU = useDelete();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [isLoaded, setIsLoaded] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [tiles, setTiles] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);

  const [queryParams, setQueryParams] = useState({
    language: searchParams.get('language') || ''
  });

  const loadData = () => {
    setIsDataLoading(true);
    setIsLoaded(true);

    let endpoint = `tiles?page=${page + 1}&limit=${limit}`;


    getU(endpoint)
      .then((resp) => {
        if (resp.length > 0) {
          setTiles(resp);
          setCount(resp.length || 0);
        }
        setIsDataLoading(false);
      })
      .catch((err) => {
        setUsers([]);
        setCount(0);
        setIsDataLoading(false);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  };

  const handleChangeQueryParams = (event) => {
    setQueryParams({
      ...queryParams,
      [event.target.name]: event.target.value.toUpperCase()
    });
  };

  const handleFilterQueryParams = () => {
    const params = {};
    if (queryParams.tile !== '') {
      params.tile = queryParams.tile;
    }
    if (Object.keys(params).length !== 0) {
      setSearchParams(params);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

   const onDelete = (id) => {
     confirm({
       title: 'Удаление',
       content: 'Вы уверены, что хотите удалить предприятие?',
       onConfirm: () => {
         deleteU(`tiles/${id}`)
           .then((resp) => {
             if (resp.status === 'success') {
               loadData();
             }
           })
           .catch((err) => {
            console.log(err.resp)
           });
       }
     });
   };

  useEffect(() => {
    if (queryParams.tile === '') {
      searchParams.delete('tile');
      setSearchParams(searchParams);
    }
  }, [queryParams]);

  useEffect(() => {
    loadData();
  }, [page, limit, searchParams]);

  if (isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Малое поле</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">Малое поле</Box>
      </Box>
      <Box
        sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}
      >
        <Container maxWidth={false}>
          {isDataLoading ? (
            <UserListSkelet />
          ) : (
            <>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{ marginLeft: 2 }}>
                  <Link to="/app/small-field/add">
                    <Button color="primary" variant="contained">
                      Добавить предприятие
                    </Button>
                  </Link>
                </Box>
              </Box>
              <Box sx={{ pt: 3 }}>
                <Card>
                  <PerfectScrollbar>
                    <Box sx={{ maWidth: 1300 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          mx: 2,
                          mb: 1
                        }}
                      >
                      </Box>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ width: 80 }}>Id</TableCell>
                            <TableCell>Номер ячейки</TableCell>
                            <TableCell>Название</TableCell>
                            <TableCell>Картинка</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {tiles?.map((tile) => (
                            <TableRow hover key={tile.id}>
                              <TableCell sx={{ width: 80 }}>
                                {tile.id}
                              </TableCell>
                              <TableCell sx={{ width: 160 }}>
                                {tile.cellNumber}
                              </TableCell>
                              <TableCell>{tile.name || '---'}</TableCell>
                              <TableCell>
                                <Box
                                  sx={{ alignItems: 'center', display: 'flex' }}
                                >
                                  <Avatar
                                    src={
                                      tile.image
                                        ? `${tile.image}`
                                        : ''
                                    }
                                  />
                                </Box>
                              </TableCell>
                              <TableCell>
                                <Box sx={{ display: 'flex' }}>
                                  <Box sx={{ display: 'flex' }}>
                                    <Box sx={{ ml: 2 }}>

                                        <Button
                                          color="primary"
                                          variant="contained"
                                          onClick={() =>
                                            navigate(
                                              `/app/small-field/edit/${tile.id}`,
                                              {
                                                state: {
                                                  cellNumber: tile.cellNumber,
                                                  name: tile.name,
                                                  image: tile.image
                                                }
                                              }
                                            )
                                          }
                                        >
                                          Редакт.
                                        </Button>

                                    </Box>
                                    <Box style={{ marginLeft: '20px' }}>
                                      <Button
                                        color="error"
                                        variant="contained"
                                        onClick={(e) => e && onDelete(tile.id)}
                                      >
                                        Удалить
                                      </Button>
                                    </Box>
                                  </Box>
                                </Box>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TablePagination
                              labelRowsPerPage={
                                <span>Кол-во строк на странице:</span>
                              }
                              rowsPerPageOptions={[10, 20, 30, 40, 50]}
                              colSpan={7}
                              count={count}
                              rowsPerPage={limit}
                              page={page}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeLimit}
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </Box>
                  </PerfectScrollbar>
                </Card>
              </Box>
            </>
          )}
        </Container>
      </Box>
    </>
  );
};

export default SmallFieldList;
